import archive from '../components/archive';

const $ = jQuery;
export default function () {
	openIframe();
	archiveData();
}

function openIframe() {
	$(document).on('click', '.js-podcast--play', function (e) {
		e.preventDefault();
		let activeIframe = $(this).closest('.podcast__card').find('.js-podcast--iframe');

		if (!activeIframe.hasClass('active')) {
			let lastActiveIframes = $('.js-podcast--iframe.active');
			reloadIframe(lastActiveIframes.find('iframe'));
			let timeout = 0;

			if ($(lastActiveIframes).hasClass('active')) {
				$(lastActiveIframes).slideUp(150).removeClass('active');
				timeout = 150;
			}

			setTimeout(() => {
				activeIframe.slideDown(300).addClass('active');
			}, timeout);
		} else {
			reloadIframe(activeIframe.find('iframe'));
			activeIframe.slideUp(150).removeClass('active');
		}
	});

	$(document).on('click', '.js-podcast--iframe-close', function (e) {
		e.preventDefault();
		let lastActiveIframe = $('.js-podcast--iframe.active');
		reloadIframe(lastActiveIframe.find('iframe'));
		lastActiveIframe.slideUp(150).removeClass('active');
	});

	function reloadIframe(iframe) {
		let lastActiveIframesSrc;
		if (iframe && iframe.length > 0) {
			lastActiveIframesSrc = iframe[0].src;

			// Local environment fix
			if (lastActiveIframesSrc === undefined) {
				lastActiveIframesSrc = iframe[0].dataset.src;
			}

			iframe[0].src = lastActiveIframesSrc;
		}
	}
}

function archiveData() {
	const containers = {
		archive     : '.podcast',
		filters     : '.podcast',
		pagination  : '.podcast__pagination',
		posts       : '.js-podcasts-archive--posts',
		postsPerPage: 8,
	};

	archive(containers, 'menaGetPodcastsAjax');
}
