export default function VideoHero() {
	const video = document.getElementById('myVideo');
	const playButton = document.querySelector('.intro__play');

	// Check if the playButton element exists before adding the event listener
	if (playButton) {
		playButton.addEventListener('click', togglePlay);
	}

	function togglePlay() {
		if (video.paused) {
			video.play();
			video.setAttribute('controls', '');
			playButton.classList.add('active');
		} else {
			video.removeAttribute('controls');
			playButton.classList.remove('active');
			video.pause();
		}
	}

	video.addEventListener('ended', videoEnded);

	function videoEnded() {
		if (playButton) {
			video.removeAttribute('controls');
			playButton.classList.remove('active');
		}
	}
}
