import archive from '../components/archive';

export default function () {
	const containers = {
		archive     : '.archive-news',
		filters     : '.archive-news',
		pagination  : '.archive-news__pagination',
		posts       : '.js-event-archive--posts',
		postsPerPage: 9,
	};

	archive(containers, 'menaGetNewsAjax');
}
