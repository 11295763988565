const $ = jQuery;

export default function (containers, ajaxAction) {
	const filtersContainer = $(containers.filters);
	const archive = $(containers.archive);
	let postsContainer = $(containers.posts);
	let paginationContainerClass = $(containers.pagination);
	let postsPerPage = containers.postsPerPage;
	let paginationContainer;

	pagination(archive);
	resetFilters();

	if ($('.js-archive--dropdown').length > 0) {
		archiveDropdown();
	}

	if (filtersContainer.length > 0) {
		getPostsSearchValue(archive);
		activateFilters(filtersContainer);
	}

	function scrollToContainer() {
		// Scroll to .events-archive__intro
		$('html, body').animate(
			{
				scrollTop: $('.events-archive__intro').offset().top,
			},
			500
		);
	}

	function archiveDropdown() {
		let dropdown = $(archive).find('.js-archive--dropdown');

		$(dropdown).on('click', function (e) {
			e.preventDefault();
			$(this).toggleClass('active');
		});

		$(document).on('click', function (e) {
			if (!dropdown.is(e.target) && dropdown.has(e.target).length === 0) {
				if (dropdown.hasClass('active')) {
					dropdown.removeClass('active');
				}
			}
		});
	}

	function resetFilters() {
		$(archive).on('click', '.js-no-result--reset', function (e) {
			e.preventDefault();
			$(filtersContainer).find('.js-archive--filter').removeClass('active');
			$(filtersContainer).find('.js-archive--filter[data-term-id="0"]').addClass('active');
			$(archive).find('.mena-search input').val('');
			getPosts();
			scrollToContainer();
		});
	}

	function activateFilters() {
		$(filtersContainer).on('click', '.js-archive--filter', function (e) {
			e.preventDefault();

			if (!$(this).hasClass('active')) {
				$('.js-archive--filter').removeClass('active');
				$(this).addClass('active');
				paginationContainer = $(this).closest(archive).find(paginationContainerClass);
				getPosts();
				scrollToContainer();
			}
		});
	}

	function getPostsTerms(container) {
		let allSelects = $(container).find('.js-archive--taxonomy');
		let label = $(container).find('.js-archive--label');
		let terms = new Object;

		if (allSelects.length > 0) {
			allSelects.each(function (i, select) {
				let taxonomy = $(select).data('taxonomy');
				terms[taxonomy] = $(container).find(`.js-archive--filter.active`).data('term-id');

				if (label.length > 0) {
					let termText = $(container).find(`.js-archive--filter.active`).text();

					if (termText) {
						label.text(termText);
					}
				}
			});
		}

		return terms;
	}

	function getPostsSearchValue(container) {
		const inputField = $(container).find('.mena-search input');

		if (inputField.length > 0) {
			$(inputField).siblings('.mena-search__cta').on('click', function () {
				paginationContainer = $(this).closest(archive).find(paginationContainerClass);
				getPosts();
				scrollToContainer();
			});

			$(inputField).on('keypress', function (e) {
				if (e.which === 13) {
					paginationContainer = $(this).closest(archive).find(paginationContainerClass);
					getPosts();
					scrollToContainer();
				}
			});
		}
	}

	function pagination() {
		$(archive).on('click', '.page-numbers', function (e) {
			e.preventDefault();
			let pageNumber;
			let additionalData = new Object;
			paginationContainer = $(this).closest(archive).find(paginationContainerClass);

			if ((!$(this).hasClass('next')) && (!$(this).hasClass('prev'))) {
				pageNumber = $(this).text();
			} else {
				let activeNum = $(this).closest('.pagination').find('.page-numbers.current');
				if ($(this).hasClass('next')) {
					pageNumber = activeNum.next().text();
				}
				if ($(this).hasClass('prev')) {
					pageNumber = activeNum.prev().text();
				}
			}

			pageNumber = parseInt(pageNumber);

			// Events
			if (($(this).closest('.events').length > 0)) {
				let type = $(this).closest('.events').data('type');
				postsContainer = $(this).closest('.events').find('.js-event-archive--posts');

				if ($(this).closest('.events').hasClass('events--past')) {
					additionalData['past'] = true;
				}

				if (type) {
					additionalData['terms'] = {
						'event_type': type,
					};
				}

			}

			getPosts(pageNumber, additionalData);
			scrollToContainer();
		});
	}

	function getPosts(paged = 1, additionalData = {}) {
		let data = new Object;
		let searchValue = $(archive).find('.mena-search input').val();
		data['paged'] = paged;

		if (postsPerPage > 0) {
			data['postsPerPage'] = postsPerPage;
		}

		if (filtersContainer.length > 0) {
			let terms = getPostsTerms(filtersContainer);

			if (terms) {
				data['terms'] = terms;
			}
		}

		if (searchValue) {
			data['s'] = searchValue;
		}

		if (additionalData) {
			data = { ...data, ...additionalData };
		}
		getPostsAjax(data);
	}

	function getPostsAjax(data) {
		$.ajax({
			type    : 'GET',
			url     : menaObject.ajaxurl,
			dataType: 'html',
			data    : {
				action: ajaxAction,
				data  : data,
			},

			beforeSend: function () {
				$(postsContainer).addClass('loading');
			},

			success: function (response) {
				if (response) {
					response = JSON.parse(response);

					if (response.html) {
						$(postsContainer).html(response.html);
					}

					if (response.pagination) {
						$(paginationContainer).html(response.pagination);
					} else {
						$(paginationContainer).html('');
					}

					$(postsContainer).removeClass('loading');
				}
			},
		});
	}
}
