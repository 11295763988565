import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

export default function () {
	const locationContainer = document.querySelector('.js-locations--posts');
	const locationCards = document.querySelectorAll('.js-locations--post');
	initMap();

	locationCards.forEach((card) => {
		card.addEventListener('mouseover', function () {
			let postId = this.dataset.id;
			let marker = document.querySelector(`.mapboxgl-marker[data-post-id="${postId}"]`);
			if (marker) {
				marker.classList.add('active');
			}
		});
	});

	locationCards.forEach((card) => {
		card.addEventListener('mouseout', function () {
			let postId = this.dataset.id;
			let marker = document.querySelector(`.mapboxgl-marker[data-post-id="${postId}"]`);
			if (marker) {
				marker.classList.remove('active');
			}
		});
	});

	function initMap() {
		mapboxgl.accessToken = 'pk.eyJ1Ijoib21lbi1lc2FpYyIsImEiOiJjbG9paGIyeTgxaGowMmlxcGN2djk1dDE0In0.v5d-PfC-4VDb_32bSBvkfg';

		const mapPin = '<svg xmlns="http://www.w3.org/2000/svg" width="30" height="31" viewBox="0 0 30 31" fill="none">' +
			'<g clip-path="url(#clip0_243_7173)">' +
			'<path class="marker-svg-path-1" d="M26.25 12.5518C26.25 21.3018 15 28.8018 15 28.8018C15 28.8018 3.75 21.3018 3.75 12.5518C3.75 9.56807 4.93526 6.70659 7.04505 4.59681C9.15483 2.48702 12.0163 1.30176 15 1.30176C17.9837 1.30176 20.8452 2.48702 22.955 4.59681C25.0647 6.70659 26.25 9.56807 26.25 12.5518Z" fill="#F5F5F5" stroke="#5082FA" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>' +
			'<path fill="#fff" d="M15 16.3018C17.0711 16.3018 18.75 14.6228 18.75 12.5518C18.75 10.4807 17.0711 8.80176 15 8.80176C12.9289 8.80176 11.25 10.4807 11.25 12.5518C11.25 14.6228 12.9289 16.3018 15 16.3018Z" stroke="#5082FA" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>' +
			'</g>' +
			'<defs>' +
			'<clipPath id="clip0_243_7173">' +
			'<rect width="30" height="30" fill="white" transform="translate(0 0.0517578)"/>' +
			'</clipPath>' +
			'</defs>' +
			'</svg>';

		const map = new mapboxgl.Map({
			container: 'locations-map',
			style    : 'mapbox://styles/mapbox/light-v11',
			center   : [13.4050, 52.5200],
			zoom     : 3.5,
		});

		locationCards.forEach((card) => {
			let id = card.dataset.id;
			let lat = card.dataset.lat;
			let lng = card.dataset.lng;

			let marker = new mapboxgl.Marker(
				{
					element: createCustomMarker(mapPin),
					anchor : 'bottom',
				},
			).setLngLat([lng, lat]).addTo(map);
			let markerElement = marker.getElement();
			markerElement.dataset.postId = id;

			markerElement.addEventListener('click', function () {
				let postId = this.dataset.postId;

				// Add active class to marker
				let activeMarker = document.querySelector('.mapboxgl-marker.active');
				if (activeMarker) {
					activeMarker.classList.remove('active');
				}
				this.classList.add('active');

				let locationCard = document.querySelector(`.js-locations--post[data-id="${postId}"]`);
				if (locationCard) {
					// Add active class to location card
					let activeLocationCard = document.querySelector('.js-locations--post.active');
					if (activeLocationCard) {
						activeLocationCard.classList.remove('active');
					}

					// Scroll to location card
					locationCard.classList.add('active');
					locationContainer.scrollTop = locationCard.offsetTop - locationContainer.offsetTop;
					window.scrollTo(0, locationContainer.offsetTop - 60);
				}
			});
		});

		function createCustomMarker(svgContent) {
			let div = document.createElement('div');
			div.innerHTML = svgContent;
			return div.firstChild;
		}
	}
}


