import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';
// import styles
import 'swiper/css';
import 'swiper/css/navigation';

export default function Testimonials() {
	const swiper = new Swiper('.testimonials__container', {
		slidesPerView: 1,
		spaceBetween: 16,
		grabCursor: true,
		loop: true,
		modules: [Navigation],
		navigation: {
            nextEl: '.testimonials__button-next',
            prevEl: '.testimonials__button-prev',
		},
        breakpoints: {
			640: {
				slidesPerView: 2,
                spaceBetween: 16,
			},
			1024: {
				slidesPerView: 3.1,
                spaceBetween: 16, 
			},
		},
	});
}