import Swiper from 'swiper';
import { Autoplay, Navigation } from 'swiper/modules';
// import styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/autoplay';

const $ = jQuery;

export default function () {

	const swiper = new Swiper('.slider__container', {
		slidesPerView: 1,
		spaceBetween : 0,
		grabCursor   : false,
		loop         : true,
		modules      : [Navigation, Autoplay],
		speed        : 1000,
		autoplay     : {
			delay               : 5000,
			disableOnInteraction: true,
			waitForTransition   : true,
		},
		navigation   : {
			nextEl: '.slider__button-next',
			prevEl: '.slider__button-prev',
		},
	});
}
