const $ = jQuery;
export default function () {
	filters();
}

function filters() {
	$('.js-study--filter').on('click', function () {
		const studies = $('.js-study--card');

		if (!$(this).hasClass('active')) {
			$('.js-study--filter').removeClass('active');
			$(this).addClass('active');

			let termId = $(this).data('term-id');
			studies.each(function () {
				if (termId === 'all' || $(this).data('term-id') === termId) {
					$(this).show();
				} else {
					$(this).hide();
				}
			});
		}
	});
}
