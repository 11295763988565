export default function () {
	if (document.querySelector('.sidebar')) {
		toggleSidebar();
	}

	// If over 1024px
	if (window.innerWidth > 1024) {
		const documentSidebar = document.querySelector('.document__sidebar .sidebar');
		if (!documentSidebar) return;
		// If height of sidebar is greater than height of viewport
		// Get height even if it overflows
		let sidebarHeight = documentSidebar.getBoundingClientRect().height;
		const viewportHeight = window.innerHeight;

		// Also add .header__top-bar height if exists and .header__container height
		const headerTopBar = document.querySelector('.header__top-bar');
		const headerContainer = document.querySelector('.header__container');
		if (headerTopBar) {
			const headerTopBarHeight = headerTopBar.getBoundingClientRect().height;
			sidebarHeight += headerTopBarHeight;
		}
		if (headerContainer) {
			const headerContainerHeight = headerContainer.getBoundingClientRect().height;
			sidebarHeight += headerContainerHeight;
		}

		console.log(sidebarHeight, viewportHeight);
		if (sidebarHeight + 88 > viewportHeight) {
			documentSidebar.classList.add('is-scrollable');
		}
	}
}

function toggleSidebar() {
	const buttons = document.querySelectorAll('.js-sidebar--toggle');

	buttons.forEach(button => {
		button.addEventListener('click', function (e) {
			e.preventDefault();
			document.querySelector('.document__sidebar').classList.toggle('opened');
		});
	});
}
