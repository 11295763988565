// import swiper and Pagination module
import Swiper from 'swiper';
import { Navigation, FreeMode} from 'swiper/modules';
// import  only swiper pagination styles
import 'swiper/swiper-bundle.css';
// eslint-disable-next-line
import 'swiper/css/navigation';

export default function productSlider() {
	let partnersSlider = document.querySelector('.partners__slider');

	if(!partnersSlider) {
		return;
	}
	// eslint-disable-next-line no-unused-vars
	const swiper = new Swiper(partnersSlider, {
		slidesPerView: 4.1,
		spaceBetween: 16,
		// We have to define the pagination module if we are using it
		modules: [Navigation, FreeMode],
		navigation: {
			nextEl: '.partners__slider-next',
			prevEl: '.partners__slider-prev',
		},
		breakpoints: {
			320: {
				slidesPerView: 1.1,
				spaceBetween: 8,
			},
			440: {
				slidesPerView: 2.1,
				spaceBetween: 16,
			},
			640: {
				slidesPerView: 3.1,
			},
			1024: {
				slidesPerView: 4.1,
			},
		},
	});
}
