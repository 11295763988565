import archive from '../components/archive';

const $ = jQuery;
export default function () {
	toggleCalendarIcons();

	if ($('.events-archive').length > 0) {
		archiveEventsData();
	}

	if ($('.events').length > 0) {
		eventsData();
	}

	if ($('.js-events--slider').length > 0) {
		eventsSlider();
	}
}

function toggleCalendarIcons() {
	$(document).on('click', function (e) {
		if (!e.target.classList.contains('js-event-card--calendar')) {
			if ($('.event-card__calendar-icons').hasClass('active')) {
				$('.event-card__calendar-icons.active').removeClass('active');
			}
		}
	});

	$(document).on('click', '.js-event-card--calendar', function () {
		let calendarContainer = $(this).find('.event-card__calendar-icons');

		if (calendarContainer.hasClass('active')) {
			calendarContainer.removeClass('active');
		} else {
			$('.event-card__calendar-icons.active').removeClass('active');
			calendarContainer.addClass('active');
		}
	});

	$(document).on('mouseleave', '.event-card__calendar-icons', function (e) {
		if ($('.event-card__calendar-icons').hasClass('active')) {
			$('.event-card__calendar-icons.active').removeClass('active');
		}
	});
}

function eventsData() {
	const containers = {
		archive: '.events',
		pagination: '.events-archive__pagination',
	};
	archive(containers, 'menaGetGuidelinesAjax');
}

function archiveEventsData() {
	const containers = {
		archive: '.events-archive',
		filters: '.events-archive',
		pagination: '.events-archive__pagination',
		posts: '.js-event-archive--posts',
		postsPerPage: 8,
	};

	archive(containers, 'menaGetGuidelinesAjax');
}