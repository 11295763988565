export default function verificationTool() {
	const verificationTool = document.querySelector('.verification-tool');

	if(!verificationTool) return;

	const verificationToolForm = verificationTool.querySelector('.verification-tool__form');

	verificationToolForm.addEventListener('submit', (e) => {
		e.preventDefault();

		// Get values of the form input with name verification_code
		const verificationCode = verificationToolForm.querySelector('input[name="verification_code"]').value;
		const extraData = verificationToolForm.querySelector('input[name="extra"]').value;

		// Extra data is honeypot field, if it's not empty, don't submit the form
		if(extraData) return;

		fetch("https://api.bcdiploma.com/verifier", {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify({ url: verificationCode }),
		})
		.then((response) => response.text())
		.then((data) => {
			var resultDiv = verificationTool.querySelector('.verification-tool__result');

			resultDiv.classList.remove('color-mainGreen')
			resultDiv.classList.remove('color-mainRed')


			switch (data) {
				case "Url is valid":
					resultDiv.textContent = "diploma is valid"
					resultDiv.classList.add('color-mainGreen')
					break
				case "Url is valid but diploma has expired":
					resultDiv.textContent = "The diploma was valid but has expired"
					resultDiv.classList.add('color-mainRed');
					break
				case "Url is valid but diploma has been disabled":
					resultDiv.textContent = "The diploma was valid but has been deactivated"
					resultDiv.classList.add('color-mainRed');
					break
				case "Url is valid but diploma has been subject to the right of oblivion":
					resultDiv.textContent = "The diploma has been subject to the right to be forgotten"
					resultDiv.classList.add('color-mainRed');
					break
				case "Given url is invalid":
					resultDiv.textContent = "This link is not a valid diploma"
					resultDiv.classList.add('color-mainRed');
					break
				case "Given url does not contain key":
					resultDiv.textContent = "This link is not a valid diploma"
					resultDiv.classList.add('color-mainRed');
					break
				case "The key is unknown":
					resultDiv.textContent = "This link is not a valid diploma"
					resultDiv.classList.add('color-mainRed');
					break
				case "Domain is invalid":
					resultDiv.textContent = "This link is not a valid diploma"
					resultDiv.classList.add('color-mainRed');
					break
				case "Url is valid but not issued by given issuer":
					resultDiv.textContent = "This link is not a valid diploma"
					resultDiv.classList.add('color-mainRed');
					break
			}
		})
		.catch((error) => console.error("Error :", error))
	});

}