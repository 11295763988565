import { calculateMegaMenuPosition, closeMegaMenu } from './megaMenu';

const $ = jQuery;

export default function MobileMenu() {
	toggleTopBar();
	const mobileMenuToggles = document.querySelectorAll('.js-menu-toggle');

	if (!mobileMenuToggles) {
		return;
	}

	mobileMenuToggles.forEach((toggle) => {
		toggle.addEventListener('click', () => {
			const body = document.querySelector('body');

			if (body.classList.contains('mega-menu-open')) {
				closeMegaMenu(document.querySelector('.mega-menu__item.active'));
			} else {
				mobileMenuToggles.forEach((toggle) => {
					toggle.classList.toggle('menu-toggle--active');
				});

				body.classList.toggle('menu-open');
			}
		});
	});
}

/**
 * Toggle the top bar
 */
function toggleTopBar() {
	let visible = localStorage.getItem('topBar');

	if (visible !== 'hidden') {
		$('.header__top-bar').slideDown();
	}

	const topBarClose = document.querySelector('.js-header--top-bar-close');

	if(!topBarClose) {
		return;
	}
	topBarClose.addEventListener('click', () => {
		$('.header__top-bar').slideUp();
		closeMegaMenu(document.querySelector('.mega-menu__item.active'));
		localStorage.setItem('topBar', 'hidden');

		setTimeout(() => {
			calculateMegaMenuPosition();
		}, 400);
	});
}
