/**
 * Global imports
 */
import svg4everybody from 'svg4everybody';
import MobileMenu from './components/mobile-menu';
import openSubmenu from './components/open-submenu';
import megaMenu from './components/megaMenu';
import slider from './components/slider';
import VideoHero from './components/play';
import faq from './blocks/faq';
import Testimonials from './blocks/testimonials';
import logosSlider from './blocks/logosSlider';
import tabs from './blocks/tabs';
import eventsArchive from './blocks/eventsArchive';
import newsArchive from './blocks/newsArchive';
import sidebar from './components/sidebar';
import study from './blocks/study';
import podcast from './blocks/podcast';
import locations from './blocks/locations';
import guidelinesArchive from './blocks/guidelinesArchive';
import Memberships from './blocks/memberships';
import verificationTool from './components/verificationTool';
import partners from './blocks/partners';

document.addEventListener('DOMContentLoaded', () => {
	const $ = jQuery;

	svg4everybody();
	MobileMenu();
	openSubmenu();
	megaMenu();
	faq();
	slider();
	Testimonials();
	tabs();
	sidebar();
	verificationTool();
	partners();

	if (document.querySelector('.logo-slider')) {
		logosSlider();
	}

	if (document.querySelector('.intro__play')) {
		VideoHero();
	}

	if(!document.querySelector('.guidelines-archive')) {
		if (document.querySelector('.events') || document.querySelector('.events-archive') || document.querySelector('.js-events--slider')) {
			eventsArchive();
		}
	}

	if (document.querySelector('.guidelines-archive')) {
		guidelinesArchive();
	}

	if (document.querySelector('.archive-news')) {
		newsArchive();
	}

	if (document.querySelector('.study')) {
		study();
	}

	if (document.querySelector('.podcast')) {
		podcast();
	}

	if (document.querySelector('.locations')) {
		locations();
	}

	Memberships();
});
