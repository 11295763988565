export default function () {
	const menuItems = document.querySelectorAll('.js-mega-menu-item');
	const backButtons = document.querySelectorAll('.js-mega-menu--back');
	let megaMenuActiveItem,
		megaMenuCurrentActiveItem,
		menuItemPrefix;

	search();
	setTimeout(() => {
		calculateMegaMenuPosition();
	}, 400);

	menuItems.forEach(item => {
		item.addEventListener('click', (e) => {
			e.preventDefault();

			let parentRespItem = e.target.closest('.js-mega-menu-item');
			megaMenuCurrentActiveItem = document.querySelector('.mega-menu__item.active');
			menuItemPrefix = item.classList[1].split('--')[1];
			// Hack for membership menu item
			if (menuItemPrefix === 'green') {
				menuItemPrefix = 'membership';
			}
			megaMenuActiveItem = document.querySelector(`.mega-menu__item[data-mega-menu="${menuItemPrefix}"]`);

			if (megaMenuActiveItem) {
				if (!megaMenuActiveItem.classList.contains('active')) {
					if (megaMenuCurrentActiveItem) {
						closeMegaMenu(megaMenuCurrentActiveItem);
					}

					openMegaMenu(parentRespItem, megaMenuActiveItem);
				} else {
					closeMegaMenu(megaMenuActiveItem);
				}
			} else {
				console.error(`Mega menu with prefix ${menuItemPrefix} not found`);
			}
		});
	});

	// Close mega menu on resize
	window.addEventListener('resize', function () {
		let megaMenuActiveItem = document.querySelector('.mega-menu__item.active');

		if (megaMenuActiveItem) {
			closeMegaMenu(megaMenuActiveItem);
		}

		if (window.innerWidth > 1024) {
			calculateMegaMenuPosition();
		}
	});

	// Back button
	backButtons.forEach(item => {
		item.addEventListener('click', (e) => {
			e.preventDefault();

			megaMenuActiveItem = document.querySelector('.mega-menu__item.active');

			if (megaMenuActiveItem) {
				closeMegaMenu(megaMenuActiveItem);
			}
		});
	});

}

/**
 * Calculate positions of mega menu items, and offset them by header height
 */
export function calculateMegaMenuPosition() {
	let header = document.querySelector('.header');
	let respMenu = document.querySelector('.resp-menu');
	let headerHeight = header.offsetHeight;
	let megaMenuItems = document.querySelectorAll('.mega-menu__item');

	if (window.innerWidth > 1024) {
		megaMenuItems.forEach(item => {
			item.style.height = `calc(100vh - ${headerHeight}px)`;
			item.style.top = `${headerHeight}px`;
		});

		respMenu.style.paddingTop = `${headerHeight}px`;
	}
}

/**
 * Open mega menu
 * @param item - mega menu item to open (HTMLElement) - required parameter
 */
export function openMegaMenu(parentRespItem, megaMenuItem) {
	const body = document.querySelector('body');
	body.classList.add('mega-menu-open');
	megaMenuItem.classList.add('active');

	if (parentRespItem && !parentRespItem.classList.contains('active')) {
		parentRespItem.classList.add('active');
	}
}

/**
 * Close mega menu
 * @param item - mega menu item to close (HTMLElement) - required parameter
 */
export function closeMegaMenu(megaMenuItem) {
	const body = document.querySelector('body');
	const respItem = document.querySelector('.js-mega-menu-item.active');
	megaMenuItem.classList.remove('active');
	body.classList.remove('mega-menu-open');

	if (respItem) {
		respItem.classList.remove('active');
	}
}

function search() {
	const searchButtons = document.querySelectorAll('.js-mega-menu--search-submit');

	searchButtons.forEach(button => {
		button.addEventListener('click', (e) => {
			e.preventDefault();
			const searchLoader = document.querySelector('.js-mega-menu--search-title');
			let searchInput = e.target.previousElementSibling;
			let value = searchInput.value;

			jQuery.ajax({
				type      : 'GET',
				url       : menaObject.ajaxurl,
				dataType  : 'html',
				data      : {
					action: 'menaSearch',
					s     : value,
				},
				beforeSend: function () {
					// Loader
					if (!searchLoader.classList.contains('loading')) {
						searchLoader.classList.add('loading');
					}

					// Show search container after searching in mobile menu
					if (e.target.classList.contains('js-resp-menu--search-submit')) {
						const searchContainer = document.querySelector('.mega-menu__item--search');
						const megaMenuSearchInput = document.querySelector('.mega-menu-search__search');

						openMegaMenu(false, searchContainer);
						megaMenuSearchInput.value = value;
					}
				},
				success   : function (response) {
					if (response) {
						const searchResultsContainer = document.querySelector('.js-mega-menu--search-results');
						response = JSON.parse(response);

						if (response.status === 'success') {
							searchResultsContainer.innerHTML = response.data;
						}

						if (response.status === 'error' || response.status === 'empty') {
							searchResultsContainer.innerHTML = '<h4 class="color-light">No results found</h4>';
						}
					}

					// Loader
					if (searchLoader.classList.contains('loading')) {
						searchLoader.classList.remove('loading');
					}
				},
			});
		});
	});
}
