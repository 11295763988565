export default function faq() {
    const faqItems = document.querySelectorAll('.faq__item');
    let openedItem = null;

    if(!faqItems) return;

    faqItems.forEach(faqItem => {
      faqItem.addEventListener('click', event => {
        const faqContent = faqItem.querySelector('.faq__content');

        if (faqContent) {
          const isCurrentlyOpened = faqItem === openedItem;

          if (openedItem) {
            jQuery(openedItem.querySelector('.faq__content')).slideUp();
            openedItem.classList.remove('opened');
          }

          if (!isCurrentlyOpened) {
            jQuery(faqContent).slideDown();
            faqItem.classList.add('opened');
            openedItem = faqItem;
          } else {
            openedItem = null;
          }
        }
      });
    });
  }
