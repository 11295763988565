import Swiper from 'swiper';
import { FreeMode, Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/free-mode';
import archive from '../components/archive';

const $ = jQuery;
export default function () {
	toggleCalendarIcons();

	if ($('.events-archive').length > 0) {
		archiveEventsData();
	}

	if ($('.events').length > 0) {
		eventsData();
	}

	if ($('.js-events--slider').length > 0) {
		eventsSlider();
	}
}

function toggleCalendarIcons() {
	$(document).on('click', function (e) {
		if (!e.target.classList.contains('js-event-card--calendar')) {
			if ($('.event-card__calendar-icons').hasClass('active')) {
				$('.event-card__calendar-icons.active').removeClass('active');
			}
		}
	});

	$(document).on('click', '.js-event-card--calendar', function () {
		let calendarContainer = $(this).find('.event-card__calendar-icons');

		if (calendarContainer.hasClass('active')) {
			calendarContainer.removeClass('active');
		} else {
			$('.event-card__calendar-icons.active').removeClass('active');
			calendarContainer.addClass('active');
		}
	});

	$(document).on('mouseleave', '.event-card__calendar-icons', function (e) {
		if ($('.event-card__calendar-icons').hasClass('active')) {
			$('.event-card__calendar-icons.active').removeClass('active');
		}
	});
}

function eventsData() {
	const containers = {
		archive   : '.events',
		pagination: '.events-archive__pagination',
	};

	archive(containers, 'menaGetEventsAjax');
}

function archiveEventsData() {
	const containers = {
		archive     : '.events-archive',
		filters     : '.events-archive',
		pagination  : '.events-archive__pagination',
		posts       : '.js-event-archive--posts',
		postsPerPage: 12,
	};

	archive(containers, 'menaGetEventsAjax');
}

function eventsSlider() {
	let swiper = null;
	handleSwiper();

	window.addEventListener('resize', function () {
		clearTimeout(window.resizeTimeout);
		window.resizeTimeout = setTimeout(function () {
			handleSwiper();
		}, 250);
	});

	function handleSwiper() {
		if (window.innerWidth >= 1024) {
			if (!swiper) {
				new Swiper('.js-events--slider', {
					modules      : [Navigation, FreeMode],
					slidesPerView: 2,
					spaceBetween : 16,
					grabCursor   : true,
					freeMode     : true,
					navigation   : {
						nextEl: '.events-calendar .swiper-button-next',
						prevEl: '.events-calendar .swiper-button-prev',
					},
					on           : {
						slideChange: function () {
							let activeSlideIndex = $(this.slides[this.activeIndex]).data('index');
							$(`.events-calendar__meta.active`).removeClass('active');
							$(`.events-calendar__meta[data-index="${activeSlideIndex}"]`).addClass('active');
						},
					},
				});
			}
		} else {
			if (swiper) {
				swiper.destroy();
				swiper = null;
			}
		}
	}
}
