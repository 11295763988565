export default function Memberships() {
	const membershipTypes = document.querySelectorAll('.membership__type');
	const benefitItems = document.querySelectorAll('.benefit__item');
	const membershipButtons = document.querySelectorAll('.membership__buttons .c-button');

	if(!membershipTypes.length) return;

	// Add event listener to each membership type
	membershipTypes.forEach(membershipType => {
		membershipType.addEventListener('click', function() {
			// Gete data-membership value from clicked element;
			const membership = this.dataset.membershipType;

			// Remove active class from all membership types
			membershipTypes.forEach(membershipType => {
				membershipType.classList.remove('active');
			});

			// Add active class to clicked membership type
			this.classList.add('active');

			// Remove active class from all benefit items
			benefitItems.forEach(benefitItem => {
				benefitItem.classList.remove('inactive');
			});

			// Add active class to benefit items that match the clicked membership type
			benefitItems.forEach(benefitItem => {
				// Get data-memberships value from benefit item
				const memberships = benefitItem.dataset.memberships.split(' ');

				// If benefit item does not match the clicked membership type, add inactive class
				if(!memberships.includes(membership)) {
					benefitItem.classList.add('inactive');
				} else {
					benefitItem.classList.remove('inactive');
				}

			});

			if(membershipButtons) {
				membershipButtons.forEach(membershipButton => {
					// Check if button has class same as clicked membership type
					if(membershipButton.classList.contains(membership)) {
						membershipButton.classList.add('active');
					} else {
						membershipButton.classList.remove('active');
					}
				});
			}
		});
	});
}