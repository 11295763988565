import Swiper from 'swiper';
import { Autoplay } from 'swiper/modules';

export default function () {
	const swiper = new Swiper('.js-logo-slider', {
		slidesPerView : 'auto',
		spaceBetween  : 24,
		grabCursor    : false,
		loop          : true,
		allowTouchMove: false,
		modules       : [Autoplay],
		speed         : 5000,
		autoplay      : {
			delay               : 0,
			disableOnInteraction: false,
		},
		breakpoints   : {
			1640: {
				slidesPerView: 8,
			},
		},
	});
}
