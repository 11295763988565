export default function tabs() {
    const tabsWithContentList = document.querySelectorAll('.horizontal-nav-tab-section');

    if (!tabsWithContentList) return;

    tabsWithContentList.forEach((tabsWithContent) => {
        const tabItems = tabsWithContent.querySelectorAll('.horizontal-nav-tab-section__card');
        const tabLinks = tabsWithContent.querySelectorAll('.horizontal-nav-tab-section__tab');

        // Initialize the first tab as active when the page loads
        tabItems[0].classList.add('horizontal-nav-tab-section__card--active');

        function activateTab(tabItem) {
            if (tabItem.classList.contains('horizontal-nav-tab-section__card--active')) return;

            tabItems.forEach((itemTab) => {
                itemTab.classList.remove('horizontal-nav-tab-section__card--active');
            });
            tabItem.classList.add('horizontal-nav-tab-section__card--active');
        }

        tabLinks.forEach((tabLink, index) => {
            tabLink.addEventListener('click', (event) => {
                event.preventDefault();

                if (tabLink.classList.contains('active')) return;

                const activeTabLink = tabsWithContent.querySelector('.horizontal-nav-tab-section__tab.active');
                if (activeTabLink) {
                    activeTabLink.classList.remove('active');
                }
                tabLink.classList.add('active');

                activateTab(tabItems[index]);
            });
        });
    });
}
