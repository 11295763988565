export default function openSubmenu() {

    const menuToggles = document.querySelectorAll('.menu--footer-menu-2 >.menu-item-has-children > a');

    menuToggles.forEach(menuItem => {
        menuItem.addEventListener('click', (e) => {
            e.preventDefault();
            const parent = menuItem.parentElement;
            parent.classList.toggle('active');
            const submenu = menuItem.nextElementSibling;
            jQuery(submenu).slideToggle();
        });
    });
}